<template>
  <div class="productos-por-producto mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title> Informe Productos </v-card-title>
          <v-card-subtitle>
            En este apartado podrás ver los movimientos de un producto
            determinado en un rango de fechas.
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_1"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha inicial"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_2"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_fin"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-autocomplete
                  dense
                  outlined
                  rounded
                  v-model="producto"
                  append-icon="mdi-magnify"
                  item-text="filter"
                  :items="list_venta"
                  :filter="customFilterVenta"
                  label="Selecciona un producto"
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-card
                  elevation="0"
                  outlined
                  v-if="producto.producto != undefined"
                >
                  <v-card-title> {{ producto.producto }} </v-card-title>
                  <v-card-subtitle>
                    <strong>Codigo: </strong> {{ producto.codigo }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-card
                  elevation="0"
                  outlined
                  v-if="producto.producto != undefined"
                >
                  <v-card-text>
                    <h3>
                      Entradas:
                      <strong style="color:green">{{
                        cantidades.notas +
                          cantidades.ordenes +
                          cantidades.m_entradas
                      }}</strong>
                    </h3>
                    <h3>
                      Salidas:
                      <strong style="color:red">{{
                        cantidades.facturas + cantidades.m_salidas
                      }}</strong>
                    </h3>
                    <h3>
                      Cantidad total:
                      <strong>{{
                        cantidades.notas +
                          cantidades.ordenes +
                          cantidades.m_entradas -
                          (cantidades.facturas + cantidades.m_salidas)
                      }}</strong>
                    </h3>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="12"
                sm="12"
                v-if="producto.id_activo != undefined"
              >
                <v-row dense>
                  <v-col cols="12" md="6" sm="12">
                    <informe-producto-facturas
                      :id="producto.id_activo"
                      :inicio="date"
                      :fin="date_fin"
                      v-on:setCantidades="setCantidades($event)"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <informe-producto-notas
                      :id="producto.id_activo"
                      :inicio="date"
                      :fin="date_fin"
                      v-on:setCantidades="setCantidades($event)"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <informe-producto-ordenes
                      :id="producto.id_activo"
                      :inicio="date"
                      :fin="date_fin"
                      v-on:setCantidades="setCantidades($event)"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <informe-producto-m-entradas
                      :id="producto.id_activo"
                      :inicio="date"
                      :fin="date_fin"
                      v-on:setCantidades="setCantidades($event)"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <informe-producto-m-salidas
                      :id="producto.id_activo"
                      :inicio="date"
                      :fin="date_fin"
                      v-on:setCantidades="setCantidades($event)"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
import InformeProductoFacturas from "../../components/InformeProductoFacturas.vue";
import InformeProductoNotas from "../../components/InformeProductoNotas.vue";
import InformeProductoOrdenes from "../../components/InformeProductoOrdenes.vue";
import informeProductoMEntradas from "../../components/InformeProductoMEntradas.vue";
import InformeProductoMSalidas from "../../components/InformeProductoMSalidas.vue";
moment.locale("es");
export default {
  components: {
    InformeProductoFacturas,
    InformeProductoOrdenes,
    InformeProductoNotas,
    informeProductoMEntradas,
    InformeProductoMSalidas,
  },
  name: "productosPorProducto",
  data: () => ({
    date: moment().format("YYYY-MM-DD"),
    menu_1: false,
    date_fin: moment().format("YYYY-MM-DD"),
    menu_2: false,
    productos: [],
    producto: {},
    cantidades: {
      facturas: 0,
      notas: 0,
      ordenes: 0,
      m_entradas: 0,
      m_salidas: 0,
    },
  }),
  methods: {
    loadProductos() {
      const body = {
        route: "/productos_activos",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.productos = response.data.data;
        }
      });
    },
    customFilterVenta(item, queryText, itemText) {
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      const text = removeAccents(queryText);
      const textOne = item.filter.toLowerCase();
      const searchText = text.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    setCantidades(data) {
      switch (data.type) {
        case 2:
          this.cantidades.notas = data.cantidad;
          break;

        case 3:
          this.cantidades.ordenes = data.cantidad;
          break;

        case 4:
          this.cantidades.m_entradas = data.cantidad;
          break;

        case 5:
          this.cantidades.m_salidas = data.cantidad;
          break;

        default:
          this.cantidades.facturas = data.cantidad;
          break;
      }
    },
  },
  created() {
    this.loadProductos();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    productosLista() {
      const productos = this.productos.map((producto) => {
        producto.compra = parseFloat(producto.compra);
        producto.venta = parseFloat(producto.venta);
        producto.iva_compra = parseFloat(producto.iva_compra);
        producto.iva_venta = parseFloat(producto.iva_venta);
        producto.inventario = parseInt(producto.inventario);
        let data = producto.producto + " [" + producto.codigo + "]";
        Object.assign(producto, { filter: data });
        return producto;
      });
      return productos;
    },
    list_venta() {
      return this.productosLista.filter((producto) => {
        return producto.inventario == 1;
      });
    },
  },
  watch: {
    date() {
      this.cantidades = {
        facturas: 0,
        notas: 0,
        ordenes: 0,
        m_entradas: 0,
        m_salidas: 0,
      };
      return this.date;
    },
    date_fin() {
      this.cantidades = {
        facturas: 0,
        notas: 0,
        ordenes: 0,
        m_entradas: 0,
        m_salidas: 0,
      };
      return this.date_fin;
    },
  },
};
</script>
