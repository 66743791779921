<template>
  <div class="informe-producto-movimientos-entradas">
    <v-card elevation="0" outlined>
      <v-card-title> Movimientos stock: Entradas </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :footer-props="{ itemsPerPageText: 'Ordenes' }"
          :search="search"
          :items="lista"
        >
          <!-- <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                outlined
                rounded
                dense
                append-icon="mdi-magnify"
                label="Buscar"
                class="mt-6"
                v-model="search"
              ></v-text-field>
            </v-toolbar> </template> -->
          <template v-slot:body.append>
            <tr>
              <th>Totales</th>
              <th colspan="2">{{ totalizacion }}</th>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "informeProductoMEntradas",
  props: ["id", "inicio", "fin"],
  data: () => ({
    search: "",
    headers: [
      {
        text: "Fecha",
        value: "created_at",
        align: "start",
      },
      {
        text: "Cantidad",
        value: "cantidad",
      },
      {
        text: "Obs",
        value: "observacion",
        width: 100,
      },
    ],
    entradas: [],
  }),
  methods: {
    loadDetalle() {
      const body = {
        route: "/producto_movimientos_movimientoStock_entradas",
        params: {
          id: this.id,
          date: {
            inicio: this.inicio,
            fin: this.fin,
          },
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.entradas = response.data.data;
      });
    },
  },
  created() {
    this.loadDetalle();
  },
  computed: {
    lista() {
      return this.entradas.map((orden) => {
        orden.created_at = moment(orden.created_at).format("ll");
        return orden;
      });
    },
    totalizacion() {
      const cantidad = this.lista.reduce((index, item) => {
          return index + parseFloat(item.cantidad);
        }, 0)
      this.$emit("setCantidades", {cantidad: cantidad, type: 4})
      return cantidad
    },
  },
  watch: {
    id(val) {
      this.loadDetalle();
      return this.id;
    },
    inicio(val) {
      this.loadDetalle();
      return this.inicio;
    },
    fin(val) {
      this.loadDetalle();
      return this.fin;
    },
  },
};
</script>
