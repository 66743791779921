<template>
  <div class="informe-producto-notas">
    <v-card elevation="0" outlined>
      <v-card-title> Notas Credito </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :footer-props="{ itemsPerPageText: 'Notas' }"
          :search="search"
          :items="lista"
        >
          <!-- <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                outlined
                rounded
                dense
                append-icon="mdi-magnify"
                label="Buscar"
                class="mt-6"
                v-model="search"
              ></v-text-field>
            </v-toolbar> </template> -->
          <template v-slot:item.folio="{ item }">
            <router-link :to="'/detalleNotaCredito/' + item.nota_id">
              {{ item.folio }}
            </router-link>
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.total | currency }}
          </template>
          <template v-slot:body.append>
            <tr>
              <th colspan="2">Totales</th>
              <th>{{ totalizacion.cantidad }}</th>
              <th>{{ totalizacion.total | currency }}</th>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "informeProductoNotas",
  props: ["id", "inicio", "fin"],
  data: () => ({
    search: "",
    headers: [
      {
        text: "Fecha",
        value: "fecha",
        align: "start",
      },
      {
        text: "Folio",
        value: "folio",
      },
      {
        text: "Cantidad",
        value: "cantidad",
      },
      {
        text: "Total",
        value: "total",
      },
    ],
    notas: [],
  }),
  methods: {
    loadDetalle() {
      const body = {
        route: "/producto_movimientos_notas",
        params: {
          id: this.id,
          date: {
            inicio: this.inicio,
            fin: this.fin,
          },
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.notas = response.data.data;
      });
    },
  },
  created() {
    this.loadDetalle();
  },
  computed: {
    lista() {
      return this.notas.map((nota) => {
        nota.fecha = moment(nota.fecha).format("ll");
        nota.folio = this.$options.filters.folio(nota.folio);
        nota.total = parseFloat(nota.total);
        return nota;
      });
    },
    totalizacion() {
      const totalizacion = {
        cantidad: this.lista.reduce((index, item) => {
          return index + parseFloat(item.cantidad);
        }, 0),
        total: this.lista.reduce((index, item) => {
          return index + parseFloat(item.total);
        }, 0),
      };
      this.$emit("setCantidades", { cantidad: totalizacion.cantidad, type: 2 });
      return totalizacion;
    },
  },
  watch: {
    id(val) {
      this.loadDetalle();
      return this.id;
    },
    inicio(val) {
      this.loadDetalle();
      return this.inicio;
    },
    fin(val) {
      this.loadDetalle();
      return this.fin;
    },
  },
};
</script>
